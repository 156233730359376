import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAssignment } from 'src/hooks/assignment';

/**
 * Switch between the view and edit mode of the Assignment page.
 */
export default function AssignmentDuplicate() {
  const { id: assignmentId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [assignment] = useAssignment(assignmentId);

  useEffect(() => {
    if (!assignment) {
      return;
    }

    // Extract settings from this assignment.
    const overrides = new URLSearchParams();
    overrides.set('assignment', assignment.id);
    overrides.set('name', `Copy of ${assignment.name || 'Untitled'}`);
    if (assignment.baseDocument?.meta?.writingPlan) {
      overrides.set('writingPlan', assignment.baseDocument?.meta?.writingPlan);
    }
    if (assignment.baseDocument?.meta?.hasImportantWords !== false) {
      const words = assignment.baseDocument?.meta?.importantWords || [];
      overrides.set('importantWords', words.join(','));
    }
    if (assignment.baseDocument?.meta?.hasSignalWords !== false) {
      overrides.set('hasSignalWords', 'true');
    }
    if (assignment.baseDocument?.meta?.hasWordCount !== false) {
      overrides.set('hasWordCount', 'true');
    }
    if (assignment.baseDocument?.meta?.hasWritingBuddy !== false) {
      overrides.set('hasWritingBuddy', 'true');
    }

    overrides.set('document', assignment.baseDocument?.id || '');
    navigate(
      {
        ...location,
        pathname: '/assignment/new',
        search: overrides.toString(),
      },
      {
        state: {
          prompt: assignment.instructions || '',
        },
      }
    );
  }, [assignment?.id]);

  return null;
}

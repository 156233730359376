import React, { useContext, useEffect, useRef, useState } from 'react';
import { Divider, Slider } from '@mui/material';
import { Description } from 'src/components/typography';
import { Block, BlockProperties } from 'src/types/models';
import { ImageContainer, ZoomSliderContainer } from './styles';
import { ImageAdjustContext } from '../../../../views/document-v2/ImageAdjustContext';

let dragPositionX = 0;
let dragPositionY = 0;

function ImageEditorContainer({
  block,
  isSelected,
  onChange,
  blockRef,
  isEditable,
}: {
  block: Block;
  isSelected: boolean;
  onChange?: (data: BlockProperties) => void;
  blockRef: React.RefObject<HTMLDivElement>;
  isEditable: boolean;
}) {
  const draggableRef = useRef<HTMLImageElement>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { isCropping, setIsCropping } = useContext(ImageAdjustContext);
  const [isDragging, setIsDragging] = useState(false);
  const [isTouch, setIsTouch] = useState<boolean>();
  const zoom = block.properties.zoom || 1.0;

  const [initialMousePosition, setInitialPosition] = useState({ x: 0, y: 0 });
  const [position, setPosition] = useState({
    x: block.properties.positionX || 0,
    y: block.properties.positionY || 0,
  });

  const handleMouseDown = (event: any) => {
    if (!isSelected) {
      return;
    }

    // Start the drag and drop.
    setIsDragging(true);
    dragPositionX = position.x;
    dragPositionY = position.y;

    if (event.touches?.length) {
      // This is a touch event, so listen for subsequent touch events.
      setIsTouch(true);
      setInitialPosition({
        x: event.touches[0].clientX,
        y: event.touches[0].clientY,
      });
    } else {
      event.preventDefault();
      setIsTouch(false);
      setInitialPosition({
        x: event.clientX,
        y: event.clientY,
      });
    }
  };

  const handleMouseUp = () => {
    // End the drag and drop.
    setIsDragging(false);
    setIsTouch(undefined);
    if (onChange) {
      onChange({
        positionX: dragPositionX,
        positionY: dragPositionY,
      });
    }
  };

  const handleMouseMove = (event: any) => {
    const draggableElement: HTMLImageElement | null = draggableRef.current;

    if (!isDragging || !draggableElement) return;
    if (!event.touches) event.preventDefault();

    const { clientX, clientY } = event.touches ? event.touches[0] : event;

    // Get the coordinates from when the mouse button is pressed and
    // subtract it from the current position to get the range of movement.
    const deltaX = clientX - initialMousePosition.x;
    const deltaY = clientY - initialMousePosition.y;

    const percentDeltaX = (100 * deltaX) / draggableElement.offsetWidth / zoom;
    const percentDeltaY = (100 * deltaY) / draggableElement.offsetHeight / zoom;

    let newPositionX = position.x;
    let newPositionY = position.y;
    if (block.properties.rotation === 90) {
      newPositionX += percentDeltaY;
      newPositionY -= percentDeltaX;
    } else if (block.properties.rotation === 180) {
      newPositionX -= percentDeltaX;
      newPositionY -= percentDeltaY;
    } else if (block.properties.rotation === 270) {
      newPositionX -= percentDeltaY;
      newPositionY += percentDeltaX;
    } else {
      newPositionX += percentDeltaX;
      newPositionY += percentDeltaY;
    }

    // When rotated, the coordinates have a small difference due to
    // the aspect ratio of the image. This will fix it
    const ratio =
      Math.max(draggableElement.offsetHeight, draggableElement.offsetWidth) /
      Math.min(draggableElement.offsetHeight, draggableElement.offsetWidth);
    if ([90, 270].includes(block.properties.rotation!)) {
      if (draggableElement.offsetWidth < draggableElement.offsetHeight) {
        newPositionX *= ratio;
        newPositionY /= ratio;
      } else {
        newPositionX /= ratio;
        newPositionY *= ratio;
      }
    }

    // Store the current position in global variables so that the mouseup
    // handler can access the most up-to-date values. We assume only one
    // thing will be dragged at a time.
    dragPositionX = newPositionX;
    dragPositionY = newPositionY;
    setPosition({ x: newPositionX, y: newPositionY });
  };

  useEffect(() => {
    if (isDragging && isEditable) {
      if (isTouch) {
        document.addEventListener('touchmove', handleMouseMove);
        document.addEventListener('touchend', handleMouseUp);
      } else {
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
        containerRef.current?.addEventListener('mouseup', handleMouseUp);
      }

      return () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
        containerRef.current?.removeEventListener('mouseup', handleMouseUp);
        document.removeEventListener('touchmove', handleMouseMove);
        document.removeEventListener('touchend', handleMouseUp);
      };
    }
  }, [isDragging, isTouch, containerRef.current, isEditable]);

  useEffect(() => {
    if (!isSelected && isCropping) setIsCropping(false);
  }, [isSelected]);

  const editorElementRect = blockRef.current?.getBoundingClientRect();

  let cursor = 'default';
  let zIndex = 0;
  if (isCropping && isSelected) {
    // Set image on top of text editor when cropping.
    zIndex = 2;
    cursor = 'grab';
  }
  if (isDragging) {
    cursor = 'grabbing';
  }

  if (!isEditable) {
    return (
      <ImageContainer style={{ zIndex, cursor }}>
        <img
          role="presentation"
          style={{
            transform: `rotate(${
              block.properties.rotation || 0
            }deg) scale(${zoom}) translate(${position.x}%, ${position.y}%)`,
            [block.properties.orientation === 'portrait' ? 'width' : 'height']:
              '100%',
          }}
          src={block.properties.imageUrl || ''}
          alt=""
        />
      </ImageContainer>
    );
  }

  return (
    <>
      <ImageContainer
        ref={containerRef}
        onMouseDown={(e: any) => isCropping && handleMouseDown(e)}
        onTouchStart={(e: any) => isCropping && handleMouseDown(e)}
        style={{
          zIndex,
          cursor,
        }}
      >
        <img
          ref={draggableRef}
          role="presentation"
          style={{
            transform: `rotate(${
              block.properties.rotation || 0
            }deg) scale(${zoom}) translate(${position.x}%, ${position.y}%)`,
            [block.properties.orientation === 'portrait' ? 'width' : 'height']:
              '100%',
          }}
          src={block.properties.imageUrl || ''}
          alt=""
        />
      </ImageContainer>
      {isCropping && isSelected && (
        <ZoomSliderContainer
          style={{
            top: (editorElementRect?.height || 1) + 10,
            left: '50%',
            transform: 'translateX(-50%)',
          }}
        >
          <Slider
            value={zoom}
            min={0.5}
            max={3}
            step={0.1}
            aria-labelledby="Zoom"
            classes={{ root: 'crop-slider' }}
            onChange={
              onChange
                ? (e, val) => onChange({ zoom: val as number })
                : undefined
            }
            size="small"
            style={{ flex: 5 }}
          />
          <Divider orientation="vertical" style={{ flex: 1 }} />
          <Description style={{ flex: 2, overflow: 'hidden' }}>
            {(zoom * 100).toFixed(0)}%
          </Description>
        </ZoomSliderContainer>
      )}
    </>
  );
}

export default ImageEditorContainer;

/**
 * This file is a collection of hooks for interacting with
 * generic resources from the Pressto API.
 */

import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';

/**
 * A hook for fetching a resource from the API.
 * This is designed for use with GET requests that return
 * a well-typed value at response.data.data.
 */
export function useResource<
  Func extends (...a: any[]) => Promise<AxiosResponse<any>>,
  Resource = Func extends (...a: any[]) => Promise<
    AxiosResponse<{
      data: infer R;
    }>
  >
    ? R
    : any
>(apiFunction: Func, ...args: Parameters<Func>) {
  const [resource, setResource] =
    useState<
      | (Resource & {
          $reload: () => void;
        })
      | null
    >();

  useEffect(() => {
    const load = async () => {
      try {
        const response = await apiFunction(...args);
        setResource(
          Object.assign(response.data.data, {
            $reload: load,
          })
        );
      } catch (e) {
        setResource(null);
      }
    };
    load();
  }, [...args]);

  return resource;
}

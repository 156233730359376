import React, { useMemo } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { useHubspot } from 'src/hooks/integrations/hubspot';
import { useIntercom } from 'src/hooks/integrations/intercom';
import { useAuthenticatedUser } from 'src/hooks/user';
import { DataCollector } from '../components';
import RedirectAfterLogin from './RedirectAfterLogin';
import ACTION_ROUTES from './actions';
import ADMIN_ROUTES from './admin';
import PRIVATE_ROUTES from './private';
import PUBLIC_ROUTES from './public';
import Gallery from 'src/views/gallery';

function ThirdPartyIntegrations() {
  useIntercom();
  useHubspot();
  return null;
}

function RouterRoot() {
  const user = useAuthenticatedUser();
  const isRegisteredUser = user && user?.role !== 'anonymous';
  const hasPendingActions = user?.actions && user.actions.length > 0;

  const nextActionPath = useMemo(() => {
    if (user?.actions) {
      switch (user.actions[0]) {
        case 'verify_email':
          return '/register-student';
        case 'select_role':
          return '/select-role';
        case 'add_birthdate':
          return '/birthday-form';
        case 'complete_user_data':
          return '/create-account';
        case 'add_school':
          return '/school-information-form';
        default:
          return '/gallery';
      }
    }
    return '/gallery';
  }, [user?.actions]);

  return (
    <BrowserRouter>
      <DataCollector />
      <ThirdPartyIntegrations />

      <Routes>
        {/* Routes are limited when users have compulsory actions to complete. */}
        {hasPendingActions && (
          <>
            <Route
              path="/"
              element={<Navigate to={nextActionPath} replace />}
            />
            {ACTION_ROUTES.filter(
              (e: any) => user.actions && e.action.includes(user.actions[0]),
            ).map(({ component: Element, path, name }: any) => (
              <Route
                key={name}
                path={path}
                element={
                  <Element action={user.actions ? user.actions[0] : ''} />
                }
              />
            ))}
          </>
        )}

        <Route path={'/gallery/:tab?'} element={<Gallery />} />
        {/* Users who are not registered only get public URLs. */}
        {!hasPendingActions &&
          !isRegisteredUser &&
          PUBLIC_ROUTES.map(({ component: Element, path, name }) => (
            <Route key={name} path={path} element={<Element />} />
          ))}

        {/* Users who are reigstered get private URLs. */}
        {!hasPendingActions && isRegisteredUser && (
          <>
            <Route path="/" element={<Navigate to="/gallery" replace />} />
            {PRIVATE_ROUTES.map(({ component: Element, path, name }) => (
              <Route key={name} path={path} element={<Element />} />
            ))}
          </>
        )}

        {/* A small number of administrative routes are available to admins. */}
        {(user?.role === 'admin' || user?.role === 'superadmin') &&
          ADMIN_ROUTES.map(({ component: Element, path, name }) => (
            <Route key={name} path={`/admin${path}`} element={<Element />} />
          ))}

        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>

      {/* Check whether the user should be redirected to a post-login path. */}
      <RedirectAfterLogin />
    </BrowserRouter>
  );
}

export default RouterRoot;

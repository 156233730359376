import { IconButton, TableCell, TableRow } from '@mui/material';
import React, { useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAuthenticatedUser } from 'src/hooks/user';
import { removeCoteacher } from '../../api/Classrooms';
import { BinImg, ConfigImg } from '../../assets/icons';
import { useClassroomStore } from '../../hooks/zustand/classrooms';
import { lang } from '../../lang';
import DropdownMenu from '../DropdownMenu';
import { MenuItemType, MenuRefType } from '../DropdownMenu/types';
import DeleteConfirmationDialog from '../dialogs/DeleteConfirmationDialog';
import { TeachersTableProps } from './types';

function TeachersTableRow({
  row,
  columns,
  expanded,
  onClick,
}: TeachersTableProps) {
  const menuRef = useRef<MenuRefType>(null);

  const [isDeleteConfirmationDialogOpen, setIsDeleteConfirmationDialogOpen] =
    useState(false);

  const { cid } = useParams();

  const getClassroomData = useClassroomStore((state) => state.getClassroomData);
  const classroom = useClassroomStore((state) => state.currentClassroom);
  const user = useAuthenticatedUser();

  const menuItems: MenuItemType[] = useMemo(
    () => [
      {
        key: 'remove',
        label: 'Remove',
        icon: { src: BinImg, alt: 'remove' },
        onClick: () => {
          menuRef.current?.close();
          setIsDeleteConfirmationDialogOpen(true);
        },
      },
    ],
    [menuRef],
  );

  const handleRemoveCoteacher = async () => {
    menuRef.current?.close();
    if (!cid) return;

    const response = await removeCoteacher(cid, row.id);
    if (response.status === 200) {
      await getClassroomData(cid!);
    }
  };

  return (
    <>
      <TableRow
        style={{ height: '3.5rem', transform: expanded ? 'scale(1.05)' : '' }}
        hover
        tabIndex={-1}
        key={row.id}
        className="data-row"
        onClick={() => onClick(row.id)}
      >
        {columns.map((column: any) => {
          if (
            column.id === 'actions' &&
            // Current user is an owner teacher of this classroom.
            classroom?.owner_teacher_encoded_ids.includes(user!.id) &&
            // Current user is not the teacher in this row.
            row.id !== user?.id
          ) {
            // If the current user cannot remove this teacher,
            // do not display the config icon at all.
            // If/when actions other than removal are available,
            // the conditional above should be moved down to instead affect
            // the items in the menu.
            return (
              <TableCell
                sx={{
                  borderBottom: 'none',
                  padding: '.75rem 1.5rem .75rem 0 !important',
                  width: '2rem',
                }}
                key="actions"
                className="actions"
              >
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    menuRef.current?.open(e);
                  }}
                >
                  <img src={ConfigImg} alt="co-teacher menu" />
                </IconButton>
              </TableCell>
            );
          }

          const value =
            column.id === 'name'
              ? `${row.name ?? ''} ${row.surname ?? ''}`
              : row[column.id];
          return (
            <TableCell
              sx={{ borderBottom: 'none' }}
              key={column.id}
              align={column.align}
              className={column.id}
            >
              {value}
              {expanded && (
                <>
                  <p className="last_session-expanded">
                    {`${lang('classroom.classroom_page.last_session')} ${
                      row.last_session
                    }`}
                  </p>
                  <p className="email-expanded">{row.email}</p>
                </>
              )}
            </TableCell>
          );
        })}
      </TableRow>
      <DropdownMenu
        items={menuItems}
        ref={menuRef}
        ignoreResponsiveness
        buttonStyles={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '.5rem',
        }}
      />
      <DeleteConfirmationDialog
        title={lang('classroom.remove_coteacher_dialog.title')}
        description={lang('classroom.remove_coteacher_dialog.subtitle')}
        onSubmit={handleRemoveCoteacher}
        isOpen={isDeleteConfirmationDialogOpen}
        onClose={() => setIsDeleteConfirmationDialogOpen(false)}
      />
    </>
  );
}

export default TeachersTableRow;

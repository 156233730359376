import React, { useState } from 'react';

import { useLocation } from 'react-router-dom';
import * as UserAPI from '../../api/User';
import {
  FlexBox,
  Form,
  GoogleButton,
  GradientContainer,
  Input,
  InputPassword,
  Logo,
} from '../../components';
import PrivacyAndTerms from '../../components/PrivacyAndTerms';
import { Button } from '../../components/buttons';
import { Description, Link, Title } from '../../components/typography';
import { useUserStore } from '../../hooks/zustand/user';
import { lang } from '../../lang';
import { verifyPassword } from '../../utils';

function parseError(err: { key: string; message?: string }) {
  return err.message || lang('auth.errors.email_not_found');
}

function SignIn() {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [inputErrors, setInputErrors] = useState([false, false]);
  const [errorMessage, setErrorMessage] = useState('');

  const handleChangeEmail = (e: string) => {
    setEmail(e);
    setInputErrors([false, false]);
  };

  const handleChangePassword = (e: string) => {
    setPassword(e);
    setInputErrors([false, false]);
  };

  const setUser = useUserStore((state) => state.setUser);

  const searchParams = new URLSearchParams(useLocation().search);
  const joinCode = searchParams.get('join_code');

  const handleSignInRequest = async () => {
    setErrorMessage('');
    try {
      const response = await UserAPI.signIn({
        username_or_email: email,
        password,
      });
      if (response.status !== 200) throw response.data;
      setUser(response.data.data);
    } catch (err: any) {
      setErrorMessage(parseError(err.response.data));
      setInputErrors([true, true]);
    }
  };

  const handleSubmit = async (e: any) => {
    try {
      setIsLoading(true);
      e.preventDefault();

      if (!verifyPassword(password)) {
        setErrorMessage(lang('auth.errors.password_too_short'));
        setInputErrors([false, true]);
        return;
      }

      await handleSignInRequest();

      if (joinCode) localStorage.setItem('joinCode', joinCode);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <GradientContainer>
      <FlexBox alignItems="center">
        <Logo to="https://www.joinpressto.com" />

        <FlexBox textAlign="center" marginY={5}>
          <Title>{lang('auth.signin.login')}</Title>
          <Description align="center">
            {lang('auth.signin.greeting')}
          </Description>
        </FlexBox>

        <FlexBox width={310} marginBottom={5}>
          <GoogleButton type="signIn" />
        </FlexBox>

        <FlexBox width={310}>
          <Description size="small">
            {lang('auth.commom.email_or_username_label')}
          </Description>
          <Form onSubmit={handleSubmit}>
            <Input
              placeholder={lang('auth.commom.email_or_username_placeholder')}
              error={inputErrors[0]}
              value={email}
              aria-label="email"
              onChange={(e: any) => handleChangeEmail(e.target.value)}
              disabled={isLoading}
            />
            <InputPassword
              placeholder={lang('auth.commom.password_placeholder')}
              error={inputErrors[1]}
              value={password}
              aria-label="password"
              invalidMessage={errorMessage}
              onChange={(e: any) => handleChangePassword(e.target.value)}
              disabled={isLoading}
            />
            <Link underline href="reset-password">
              {lang('auth.signin.forgot_password')}
            </Link>

            <Button
              type="submit"
              label={lang('auth.signin.login')}
              aria-label="submit"
              disabled={!email || !password || isLoading}
              large
            />
          </Form>
        </FlexBox>

        <Description size="small" marginTop={5} align="center">
          {lang('auth.signin.new_account')}{' '}
          <Link underline href="/register">
            {lang('auth.signin.signup')}
          </Link>
        </Description>
        <PrivacyAndTerms />
      </FlexBox>
    </GradientContainer>
  );
}

export default SignIn;

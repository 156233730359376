import jsonAutocomplete from 'json-autocomplete';
import OpenAI from 'openai';
import { getBlockType } from 'src/configuration/blocks';
import { DOCUMENT_TEMPLATES } from 'src/configuration/templates';
import { getWritingPlan } from 'src/configuration/writing-plans';
import { Assignment, BlockTypesEnum, Document } from 'src/types/models';
import { allBlocks } from 'src/utils/documents';
import API from './config';

const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,

  // TODO: While in development. Remove in production.
  dangerouslyAllowBrowser: true,
});

async function textFromTopic(topic: string): Promise<string> {
  const API_KEY = '134cb3dac9xa6b386d7bx4d8a6daa34a49897x3e90e'.replace(
    /x/g,
    ''
  );

  let text: string;
  try {
    // Check whether topic is a URL. This will raise an exception if
    // it is not.
    const url = new URL(topic.trim());
    const response = await fetch(
      `https://extractorapi.com/api/v1/extractor/?apikey=${API_KEY}&url=${url}`
    );
    const data = await response.json();
    text = `# ${data.title}\n\n` + `[Source](${data.url})\n\n${data.text}`;
  } catch (_) {
    text = topic;
  }

  // Return the first 30,000 characters of the text.
  return text.substring(0, 30000);
}
export async function suggestImportantWordsForPrompt(
  text: string,
  topic: string,
  writingPlan: string,
  gradeLevel = '4th'
) {
  return API.post<{
    data: {
      words: string[];
    }[];
  }>('/ai/important-words', {
    text,
    topic,
    grade_level: gradeLevel,
    writing_plan: writingPlan,
  });
}

export async function* selectDocumentTemplate(
  topic: string,
  templates: typeof DOCUMENT_TEMPLATES,
  imageBlob?: Blob
): AsyncGenerator<{
  templateId?: string;
  writingPrompt?: string;
  title?: string;
  text: string;
}> {
  const OUTPUT_STRUCTURE: any = {
    templateId: 'string',
    title: 'string',
    writingPrompt: 'string',
  };
  if (imageBlob) {
    OUTPUT_STRUCTURE.imageSummary = 'string';
  }

  const text = await textFromTopic(topic);

  const prompt = `
  Students will read a text in class. Select an appropriate writing plan
  with which students will write a response. Also, provide an appropriate
  writing prompt and a 3 – 5 word assignment title. Lastly, draft a sentence starter
  to help students begin their writing. The sentence starter should be just the beginning
  of a sentence and end with an ellipsis, the intent of which is to encourage students to
  complete the sentence in their own words.

  The writing template choices will be given, and then the text (demarcated by backticks)
  or image of the subject of the lesson. If an image is given, provide a brief summary
  of the image in the output JSON.

  Respond with a JSON object conforming to the following schema:
  ${JSON.stringify(OUTPUT_STRUCTURE, null, 2)}

  Template Choices:
  ${JSON.stringify(
    templates.map((t) => ({
      templateId: t.documentId,
      title: t.name,
      description: getWritingPlan(t.writingPlanId)?.description,
    })),
    null,
    2
  )}

  Text or Topic: ${
    imageBlob ? '(see image)' : `\`\`\`\n${text.substring(0, 1000)}\n\`\`\``
  }
  `;

  const imageDataUrl = imageBlob
    ? await new Promise<string>((resolve) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result as string);
        };
        reader.readAsDataURL(imageBlob);
      })
    : undefined;

  const response = await openai.chat.completions.create({
    model: 'gpt-4-turbo',
    messages: [
      {
        role: 'system',
        content: `
          You are a digital teaching assistant, supporting a teacher draft an assignment.
          You must integrate with a writing application by outputting JSON.
        `,
      },
      {
        role: 'user',
        content: imageDataUrl
          ? [
              {
                type: 'text',
                text: prompt,
              },
              {
                type: 'image_url',
                image_url: {
                  url: imageDataUrl,
                  detail: 'auto',
                },
              },
            ]
          : prompt,
      },
    ],
    max_tokens: imageDataUrl ? 500 : 300,
    response_format: {
      type: 'json_object',
    },
    stream: true,
  });

  let partialResult = '';
  for await (const chunk of response) {
    partialResult += chunk.choices[0]?.delta?.content || '';
    try {
      const choice: {
        templateId?: string;
        writingPrompt?: string;
        title?: string;
        imageSummary?: string;
      } = JSON.parse(jsonAutocomplete(partialResult));
      yield {
        ...choice,
        text: text + (choice.imageSummary ? `\n\n${choice.imageSummary}` : ''),
      };
    } catch (e) {}
  }
}

export async function* generateSentenceCompletions(
  text: string,
  document: Document,
  assignment: Assignment
): AsyncGenerator<{ blockId: string; starter: string }[]> {
  const OUTPUT_STRUCTURE = {
    sentenceStarters: [
      {
        paragraphNumber: 'number',
        starter: 'string',
      },
    ],
  };

  const blocks = allBlocks(document.version).filter(
    (block) =>
      getBlockType(block).configuration.hasText &&
      block.typeId !== BlockTypesEnum.Title
  );
  const writingPlan = document.meta.writingPlan
    ? getWritingPlan(document.meta.writingPlan)
    : null;

  const worksheet = {
    writingPrompt: assignment.instructions || '',
    writingPlan: writingPlan?.title || 'free write',
    paragraphs: blocks.map((block, n) => ({
      paragraphNumber: n + 1,
      type: getBlockType(block).title,
    })),
  };

  const prompt = `
  Elementary-school students will read a text in class, and then complete a writing worksheet
  inspired by the text.
  For each paragraph in the writing worksheet, a sentence starter will be provided
  that will give students a hint about what to write. The worksheet has a
  predefined paragraph structure.

  I will give you a copy of the text that will be read and a description of the
  writing worksheet as JSON. Generate sentence starters for each paragraph in the worksheet.
  Sentence starters should partial sentences that are 4 - 8 words long and end in ellipses.
  Your output must conform to a JSON structure. Some paragraphs do not have
  sentence starters and are skipped, so there may be gaps between numbers.

  Output structure:
  ${JSON.stringify(OUTPUT_STRUCTURE, null, 2)}

  Text read by students:
  \`\`\`
  ${text}
  \`\`\`

  Writing Worksheet:
  ${JSON.stringify(worksheet, null, 2)}
  `;

  const response = await openai.chat.completions.create({
    model: 'gpt-4-turbo',
    messages: [
      {
        role: 'system',
        content: `
          You are a digital teaching assistant, supporting a teacher draft an assignment.
          You must integrate with a writing application by outputting JSON.
        `,
      },
      {
        role: 'user',
        content: prompt,
      },
    ],
    max_tokens: 50 * blocks.length,
    response_format: {
      type: 'json_object',
    },
    stream: true,
  });

  let partialResult = '';
  // eslint-disable-next-line no-restricted-syntax
  for await (const chunk of response) {
    partialResult += chunk.choices[0]?.delta?.content || '';
    try {
      // Handle partial JSON by closing open tokens.
      const starters: {
        sentenceStarters?: {
          paragraphNumber?: number;
          starter?: string;
        }[];
      } = JSON.parse(jsonAutocomplete(partialResult));

      const result: { blockId: string; starter: string }[] = [];
      if (Array.isArray(starters?.sentenceStarters)) {
        // Map each block's number to its id. Be extra defensive handling the
        // input because it is being generated by GPT.
        for (const starter of starters.sentenceStarters) {
          if (starter.paragraphNumber) {
            const number = parseInt(starter.paragraphNumber.toString(), 10);
            const block = blocks.find((b, n) => n === number - 1);
            if (block) {
              result.push({
                blockId: block.id,
                starter: starter.starter || '',
              });
            }
          }
        }
      }
      yield result;
    } catch (e) {}
  }
}

export async function suggestWritingPrompt(
  topic: string,
  gradeLevel = '4th',
  skills: string[] = []
) {
  return API.post<{
    data: {
      text: string;
    }[];
  }>('/ai/writing-prompts', {
    topic,
    grade_level: gradeLevel,
    writing_plan: skills[0],
  });
}

export async function askWritingBuddy(
  paragraphs: {
    text: string;
    blockName: string;
  }[],
  currentBlockName: string,
  writingPlanTitle?: string,
  availableBlockNames?: string[],
  instructions?: string
) {
  return API.post<{
    data: {
      text: string;
      prompt?: string;
      entities: any[];
    }[];
  }>('/ai/writing-hints', {
    paragraphs,
    current_block_name: currentBlockName,
    writing_plan: writingPlanTitle,
    available_block_names: availableBlockNames,
    instructions: instructions || undefined,
  });
}

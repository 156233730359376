import React, { useState } from 'react';

import { Theme, ThemeProvider, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import { useLocation, useNavigate } from 'react-router-dom';

import { DocumentTemplateSystem } from 'src/types/DocumentSettings';
import { theme } from 'src/utils';

import { useAuthenticatedUser } from 'src/hooks/user';
import * as NotesAPI from '../../api/Notes';
import { ArrowLeftImg, LargeBackArrowImg } from '../../assets/icons';
import { LogoImg, LogoSmallImg } from '../../assets/images';
import { useEditor } from '../../hooks/useEditor';
import { useDocumentStore } from '../../hooks/zustand/documents';
import { lang } from '../../lang';
import AvatarMenu from '../AvatarMenu';
import HeaderContainer from '../HeaderContainer';
import Button from '../buttons/Button';
import TurnAssignmentDialog from '../dialogs/TurnAssignmentDialog';
import { Description, Title } from '../typography';
import ExportButtonGroup from './ExportButtonGroup';
import HistoryButtonGroup from './HistoryButtonGroup';
import ProjectInfo from './ProjectInfo';
import { TopBarContainer, TopBarWrapper } from './styles';
import { TopBarProps } from './types';

import { Chevron } from '../../assets/icons/variantComponents';
import NoteButton from '../shared/note/NoteButton';

function TopBar({
  showBackButton = true,
  viewOnlyMode = false,
  isAssignment = false,
  isTurnedIn = false,
  onUnsubmit = () => {},
}: TopBarProps) {
  const mobileMQ = useMediaQuery((thm: Theme) => thm.breakpoints.down('sm'));

  const user = useAuthenticatedUser();
  const isRegisteredUser = user && user.role !== 'anonymous';

  const [isTurnAssignmentDialogOpen, setIsTurnAssignmentDialogOpen] =
    useState(false);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { isViewMode, toggleViewMode, clearData } = useEditor();

  const currentDocument = useDocumentStore((state) => state.currentDocument);

  const { state } = useLocation() as { state: { prev?: string } };

  // I can see the notes button if...
  const showNotesButton = !!(
    // I am a student, this is my document, and the document has a submission.
    // The presence of a submission indicates that the document is being written
    // under the direction of a teacher;
    (
      (user?.role === 'student' &&
        currentDocument?.isOwner &&
        currentDocument?.submission) ||
      // Or, I teach the class against which this document has a submission.
      // TODO: Check to see if the teacher teachers the class against which
      // the assignment was created. This is the same check as deciding whether
      // to show the "Return" button.
      (user?.role === 'teacher' && currentDocument?.submission)
    )
  );

  // Notes can be attached to a document or a submission, but they are all
  // Note objects. They can be combined into a single array for display.
  const mapAPINote = (note: any) => ({
    ...note,
    createdAt: new Date(note.created_at),
    updatedAt: new Date(note.updated_at),
    assignmentId: note.assignment_id,
    versionId: note.document_version_id,
    user: {
      ...note.user,
      fullName: `${note.user.name} ${note.user.surname || ''}`,
    },
  });
  const [notes, setNotes] = useState(
    [
      ...(currentDocument?.submission?.assignment?.notes || []),
      ...(currentDocument?.version.notes || []),
    ].map(mapAPINote),
  );

  // The following code is temporary. The code in views/document-v2 is
  // more permanent and should be used as a reference.
  const onSaveNote = async (newNote: {
    text: string | undefined;
    id?: string;
  }) => {
    if (!newNote.id && newNote.text) {
      const {
        data: { data: note },
      } = await NotesAPI.create({
        text: newNote.text,
        document_version_id: currentDocument!.version.id,
      });
      setNotes((prevNotes) => [...prevNotes, mapAPINote(note)]);
    } else if (newNote.id && newNote.text) {
      const {
        data: { data: note },
      } = await NotesAPI.update(newNote.id, {
        text: newNote.text,
      });
      setNotes((prevNotes) =>
        prevNotes.map((prevNote) => {
          if (prevNote.id === note.id) return mapAPINote(note);
          return prevNote;
        }),
      );
    }
  };

  const onDeleteNote = async (note: { id: string }) => {
    await NotesAPI.deleteNote(note.id);
    setNotes((prevNotes) =>
      prevNotes.filter((prevNote) => prevNote.id !== note.id),
    );
  };

  const handleGoBack = () => {
    if (
      // Layout legacy: Disable goback for view mode
      currentDocument?.template_system !== DocumentTemplateSystem.LAYOUT &&
      currentDocument?.isOwner &&
      isViewMode &&
      currentDocument?.submission?.status !== 'turnedin'
    ) {
      if (pathname.includes('publish')) {
        navigate(pathname.replace('publish', 'document'));
      }
      toggleViewMode();
    } else {
      navigate(state?.prev ?? 'gallery');
      setTimeout(() => clearData(), 200);
    }
  };

  const handleLogoClick = () => {
    if (currentDocument?.isTryIt)
      window.location.assign('http://www.joinpressto.com/');
    else navigate('/');
  };

  const renderBackButton = () => {
    if (
      currentDocument?.isTryIt ||
      !user?.id ||
      (!currentDocument?.isOwner && user.role !== 'teacher')
    ) {
      return (
        <span
          className={`topbar-back-button ${
            currentDocument?.isTryIt && 'no-hover'
          }`}
          role="button"
          tabIndex={-1}
          onClick={handleLogoClick}
          onKeyDown={handleLogoClick}
          style={{ display: 'block', cursor: 'pointer', border: 'none' }}
        >
          <img src={mobileMQ ? LogoSmallImg : LogoImg} alt="Pressto" />
        </span>
      );
    }

    return (
      <Box
        className="topbar-back-button"
        onClick={handleGoBack}
        onKeyDown={handleGoBack}
      >
        {mobileMQ ? (
          <img src={LargeBackArrowImg} alt="back button" />
        ) : (
          <>
            <img src={ArrowLeftImg} alt="back button" />
            <Description size="small">{lang('general.go_back')}</Description>
          </>
        )}
      </Box>
    );
  };

  const showDocumentArrows = Boolean(
    currentDocument?.prev || currentDocument?.next,
  );

  const handleNavigate = (id?: string) => {
    if (!id) return;
    navigate(`/document/${id}`, { replace: true });
  };

  const renderArrow = (direction: 'left' | 'right', link?: string) =>
    showDocumentArrows ? (
      <Box
        style={!link ? { cursor: 'unset' } : undefined}
        onClick={() => handleNavigate(link)}
      >
        <Chevron direction={direction} height={16} width={16} strokeWidth={2} />
      </Box>
    ) : null;

  return isViewMode ? (
    <HeaderContainer hideTabs>
      <TopBarWrapper>
        <TopBarContainer style={{ justifyContent: 'space-between' }}>
          <div>{renderBackButton()}</div>
          <div className="topbar-center">
            <div>
              <Title size="small" color="black">
                {currentDocument?.name}
              </Title>
            </div>
            <div>
              <div>{renderArrow('left', currentDocument?.prev)}</div>
              <Description align="center">
                {currentDocument?.author}
                {currentDocument?.submission && user?.role === 'teacher' && (
                  <span style={{ textTransform: 'capitalize' }}>
                    {' '}
                    -{' '}
                    {currentDocument?.submission?.status === 'turnedin'
                      ? 'turned in'
                      : currentDocument?.submission.status}
                  </span>
                )}
              </Description>
              <div>{renderArrow('right', currentDocument?.next)}</div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              gap: 32,
              width: 'auto',
              height: 40,
            }}
          >
            {showNotesButton && (
              // See the comment in views/document-v2 about the seeming profusion
              // of theme providers.
              <ThemeProvider theme={theme}>
                <NoteButton
                  notes={notes}
                  showCreateForm={user.role === 'teacher'}
                  onSave={onSaveNote}
                  onDelete={onDeleteNote}
                />
              </ThemeProvider>
            )}
            {currentDocument?.isOwner && isTurnedIn && isAssignment && (
              <>
                <Button
                  label={lang('assignment.common.unsubmit')}
                  onClick={onUnsubmit}
                />
                <Button label={lang('assignment.common.turnin')} disabled />
              </>
            )}
            {
              // TODO: Check that the teacher teaches the student. Or check that the
              // assignment was given by the teacher.
              !currentDocument?.isOwner &&
                user?.role === 'teacher' &&
                isTurnedIn &&
                isAssignment && (
                  <Button
                    label={lang('assignment.common.return')}
                    onClick={onUnsubmit}
                  />
                )
            }
            {!currentDocument?.isOwner &&
              user?.role === 'teacher' &&
              currentDocument?.submission?.status === 'returned' && (
                <Button label={lang('assignment.common.returned')} disabled />
              )}
            {isRegisteredUser && (
              <>
                <ExportButtonGroup viewOnlyMode />
                <AvatarMenu />
              </>
            )}
            {!isRegisteredUser && viewOnlyMode && (
              <>
                <Button
                  outline
                  label={lang('auth.signin.login')}
                  onClick={() => navigate('/')}
                />
                <Button
                  label={lang('auth.signin.register')}
                  onClick={() => navigate('/register')}
                />
              </>
            )}
          </div>
        </TopBarContainer>
      </TopBarWrapper>
    </HeaderContainer>
  ) : (
    <>
      <HeaderContainer hideTabs>
        <TopBarWrapper>
          <TopBarContainer>
            <div className="topbar-column">
              {showBackButton && !currentDocument?.isTryIt && (
                <Box
                  className="topbar-back-button"
                  role="button"
                  tabIndex={-1}
                  onClick={handleGoBack}
                  onKeyDown={handleGoBack}
                >
                  {mobileMQ ? (
                    <img src={LargeBackArrowImg} alt="back button" />
                  ) : (
                    <>
                      <img src={ArrowLeftImg} alt="back button" />
                      <Description size="small">
                        {lang('general.go_back')}
                      </Description>
                    </>
                  )}
                </Box>
              )}
              {currentDocument?.isTryIt && renderBackButton()}
              <HistoryButtonGroup />
              <ProjectInfo />
            </div>
            {currentDocument?.isTryIt ? (
              <div className="topbar-column">
                <Button
                  outline
                  label={lang('auth.signin.login')}
                  onClick={() => navigate('/')}
                />
                <Button
                  label={lang('auth.signin.register')}
                  onClick={() => navigate('/register')}
                />
              </div>
            ) : (
              <div className="topbar-column">
                <div style={{ display: 'flex', gap: '8px' }}>
                  {showNotesButton && (
                    // See the comment in views/document-v2 about the seeming profusion
                    // of theme providers.
                    <ThemeProvider theme={theme}>
                      <NoteButton
                        notes={notes}
                        showCreateForm={user.role === 'teacher'}
                        onSave={onSaveNote}
                        onDelete={onDeleteNote}
                      />
                    </ThemeProvider>
                  )}
                  {!isTurnedIn &&
                    isAssignment &&
                    currentDocument?.isOwner &&
                    user?.role !== 'teacher' && (
                      <Button
                        style={{ height: 40, whiteSpace: 'nowrap' }}
                        onClick={() => setIsTurnAssignmentDialogOpen(true)}
                        label={lang('document.top_bar.export.turn_in')}
                      />
                    )}
                </div>
                <ExportButtonGroup viewOnlyMode={viewOnlyMode} />
                <AvatarMenu />
              </div>
            )}
          </TopBarContainer>
        </TopBarWrapper>
      </HeaderContainer>
      <TurnAssignmentDialog
        assignmentId={currentDocument?.submission?.assignment_id}
        submissionId={currentDocument?.submission?.id}
        isOpen={isTurnAssignmentDialogOpen}
        onClose={() => setIsTurnAssignmentDialogOpen(false)}
      />
    </>
  );
}

export default TopBar;

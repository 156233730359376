import {
  Document,
} from '../../../../types/models';
import SynchronousDocumentOperation from './synchronous';

export default class SetImportantWordsOperation extends SynchronousDocumentOperation {
  readonly type = 'set-important-words';

  words: string[];

  isEnabled: boolean;

  constructor(documentId: string, words: string[], isEnabled = true) {
    super(documentId);
    this.words = words;
    this.isEnabled = isEnabled;
  }

  apply(document: Document): Document {
    return {
      ...document,
      meta: {
        ...document.meta,
        importantWords: this.words,
        hasImportantWords: this.isEnabled,
      },
    };
  }
}

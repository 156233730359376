import { DocumentTemplateSystem } from 'src/types/DocumentSettings';
import { FeedbackConfiguration } from 'src/types/models';
import API from './config';
import { DocumentVersionResource } from './types';

export function getAll() {
  return API.get<{
    data: {
      created_at: string;
      format: string;
      id: string;
      is_demo: boolean;
      is_public: boolean;
      meta: string;
      name: string;
      submission: any;
      thumbnail_url?: string;
      updated_at: string;
      user: {
        id?: number;
        name: string;
        surname: string;
      };
      word_count: number;
      classrooms?: {
        id: string;
        name: string;
      }[];
      students?: { id: string }[];
      turnedin_count?: number;
      assigned_count?: number;
      directly_assigned_count?: number;
      'base-document'?: {
        format: string;
        thumbnail_url: string;
        template_system: DocumentTemplateSystem;
        version: DocumentVersionResource;
      };
    }[];
  }>('/assignments');
}

export function getDocumentsSubmissions(id: string) {
  return API.get(`/assignments/${id}/documents`);
}

export function submit(aid: string, sid: string) {
  return API.post(`/assignments/${aid}/submissions/${sid}/submit`);
}

export function unsubmit(aid: string, sid: string) {
  return API.post(`/assignments/${aid}/submissions/${sid}/unsubmit`);
}

export function createSubmission(id: string) {
  return API.post(`/assignments/${id}/submissions`);
}

export function getById(id: string) {
  // Corresponds to server's TeacherAssignmentResource.
  return API.get<{
    data: {
      id: string;
      name: string;
      created_at: string;
      updated_at: string;
      instructions: string;
      classrooms: {
        id: string;
        name: string;
        provider: string;
        students: {
          id: string;
        }[];
      }[];
      students: {
        id: string;
        name: string;
        surname: string;
      }[];
      teacher: {
        id: string;
        name: string;
        surname: string;
      };
      'base-document': {
        id: string;
        name: string;
        format: string;
        meta: any;
      } | null;
      template_system: DocumentTemplateSystem;
      turnedin_count: number;
      draft_count: number;
      notes: {
        id: string;
        text: string;
        assignment_id: string;
        document_version_id: string;
        user: {
          id: string;
          name: string;
          full_name: string;
          surname: string;
        };
        created_at: string;
        updated_at: string;
      }[];

      // These fields are specific to students.
      submission?: {
        id: string;
        status: string;
        document_id: string;
        created_at: string;
        updated_at: string;
      } | null;

      // This is stored as a JSON blob server-side, so it uses client-side
      // naming conventions and types.
      feedback_configurations: FeedbackConfiguration[];
      feedback_configuration_version: number;
    };
  }>(`/assignments/${id}`);
}

export function create(params: {
  name: string;
  instructions?: string;
  document_id?: string;
  classrooms?: { id: string }[];
  students?: { id: string }[];
  note?: string;
  // feedback_configurations: FeedbackConfiguration[];
  // feedback_configuration_version: number;
}) {
  return API.post<{
    data: {
      // More properties may be returned by the server, but the important
      // thing is that the server creates an ID.
      id: string;
    };
  }>('/assignments', params);
}

export function update(
  id: string,
  params: {
    name?: string;
    instructions?: string;
    document?: {
      id: string;
    };
    classrooms?: {
      id: string;
    }[];
    students?: {
      id: string;
    }[];
    note?: string;
    // feedback_configurations?: FeedbackConfiguration[];
    // feedback_configuration_version?: number;
  }
) {
  return API.patch(`/assignments/${id}`, params);
}

export function deleteAssignment(id: string) {
  return API.delete(`/assignments/${id}`);
}

import React, { useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { ThemeProvider as MUIThemeProvider } from '@mui/material';
import { GoogleOAuthProvider } from '@react-oauth/google';

import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { theme } from './utils';
import { EditorProvider } from './hooks/useEditor';
import { useUserStore } from './hooks/zustand/user';
import RouterRoot from './router';
import AlertQueue from './components/AlertQueue';

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT ?? 'local',
    tracesSampleRate: parseFloat(
      process.env.REACT_APP_SENTRY_TRACESSAMPLERATE ?? '0.0'
    ),
  });
}

function Wrapper() {
  const fetchCsrf = useUserStore((state) => state.fetchCsrfToken);

  const [loading, setLoading] = useState<boolean>(true);

  const fetchUser = async () => {
    const response = await fetchCsrf();
    if (typeof response === 'boolean') setLoading(false);
  };

  useEffect(() => {
    fetchUser();
  }, []);

  return loading ? <div /> : <RouterRoot />;
}

function App() {
  return (
    <MUIThemeProvider theme={theme}>
      {/* Drag and drop support. */}
      <DndProvider backend={HTML5Backend}>
        <EditorProvider>
          <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_ID || ''}>
            <Wrapper />

            {/* Messages to display, such as a success message after an action */}
            <AlertQueue />
          </GoogleOAuthProvider>
        </EditorProvider>
      </DndProvider>
    </MUIThemeProvider>
  );
}

export default App;

import { useCallback, useEffect, useState } from 'react';
import { APIResourceType } from 'src/api';
import { getBlockType } from 'src/configuration/blocks';
import { DOCUMENT_TEMPLATES } from 'src/configuration/templates';
import { useDocumentStore } from 'src/hooks/store/document';
import { AddBlockOperation, InitializeVersionOperation, SetDocumentNameOperation } from 'src/hooks/store/document/operations';
import {
  BlockPage,
  BlockTypesEnum,
  Document,
  DocumentVersion,
  LayoutPage,
  isBlockPage,
} from 'src/types/models';
import * as UUID from 'uuid';
import * as DocumentAPI from '../../api/Documents';
import { DocumentFormat, DocumentTemplateSystem } from '../../types/DocumentSettings';
import { useUserStore } from '../zustand/user';
import { useApplyDocumentOperation } from './apply';
import { useSelectedBlock } from './block';

function mapAPIDocumentToModel(document: APIResourceType<typeof DocumentAPI.getById>) {
  // Parse the meta field, which is stored as a string. It could be anything.
  let meta = {
    writingPlan: null,
    importantWords: [],
    hasSignalWords: true,
    hasImportantWords: true,
    hasWordCount: true,
    hasWritingBuddy: true,
  };
  try {
    meta = { ...meta, ...JSON.parse(document.meta) };
    if (!Array.isArray(meta.importantWords)) {
      meta.importantWords = [];
    }
    if (typeof meta.writingPlan !== 'string') {
      meta.writingPlan = null;
    }
  } catch (e) { }

  return {
    id: document.id,
    name: document.name,
    meta,
    format: document.format,
    isDemo: document.is_demo,
    isPublic: document.is_public,
    user: document.user,
    thumbnailUrl: document.thumbnail_url,
    templateSystem: (
      document.template_system || DocumentTemplateSystem.LAYOUT
    ) as DocumentTemplateSystem,
    submission: document.submission ? {
      id: document.submission.id,
      status: document.submission.status,
      assignmentId: document.submission.assignment_id,
      documentId: document.submission.document_id,
      assignment: document.submission.assignment ? {
        id: document.submission.assignment.id,
        name: document.submission.assignment.name,
        createdAt: new Date(document.submission.assignment.created_at),
        updatedAt: new Date(document.submission.assignment.updated_at),
        teacher: {
          id: document.submission.assignment.teacher.id,
          name: document.submission.assignment.teacher.name,
          surname: document.submission.assignment.teacher.surname,
        },
        instructions: document.submission.assignment.instructions,
        notes: document.submission.assignment.notes?.map((note) => ({
          id: note.id,
          text: note.text,
          user: {
            id: note.user.id,
            name: note.user.name,
            surname: note.user.surname,
            fullName: note.user.full_name,
          },
          createdAt: new Date(note.created_at),
          updatedAt: new Date(note.updated_at),
          assignmentId: note.assignment_id,
          versionId: note.document_version_id,
        })) || [],
      } : null,
    } : null,
    version: {
      id: document.version.id,
      documentId: document.id,
      createdAt: new Date(document.version.created_at),
      publishedAt: document.version.published_at ? new Date(document.version.published_at) : null,
      status: document.version.status,

      /** Content is blank only for legacy, layout-based documents. */
      content: {
        schemaVersion: 20230101,
        messages: [],
        pages: [],
        ...(document.version.content || {}),
      },

      /** document.version.pages is deprecated in favor of document.version.content. */
      pages: document.version.content
        ? document.version.content.pages
        : document.version.pages?.map((page) => {
          const mappedPage = {
            id: page.id,
            documentId: document.id,
            position: page.position,
            thumbnail_url: page.thumbnail_url,
            wordCount: page.word_count,
            template: page.template,
            meta: page.meta || '{}',
          };

          // Determine whether this is a layout or block page.
          const blob = JSON.parse(page.content || '{}');
          if (Array.isArray(blob)) {
            // This is a layout page.
            (mappedPage as LayoutPage).content = blob;
          } else if (blob && blob.blocks) {
            const blockPage = mappedPage as BlockPage;
            blockPage.grid = blob;
            blockPage.grid.style = blockPage.grid.style ?? 'fixed';
            blockPage.template = 'blocks_grid';
          } else {
            // Initialize the page with default values.
            // TODO: flush this out. Should be in the store?
            (mappedPage as BlockPage).grid = {
              columns: 1,
              rows: 2,
              style: 'fixed',
              blocks: [],
            };
          }

          return mappedPage;
        }) || [],
      notes: document.version.notes?.map((note) => ({
        id: note.id,
        text: note.text,
        user: {
          id: note.user.id,
          name: note.user.name,
          surname: note.user.surname,
          fullName: note.user.full_name,
        },
        createdAt: new Date(note.created_at),
        updatedAt: new Date(note.updated_at),
        assignmentId: note.assignment_id,
        versionId: note.document_version_id,
      })) || [],
    },
  };
}

/**
 * Fetches the document from the API and maps it to the client-side Document model.
 */
async function getDocumentFromAPI(documentId: string, isPublished: boolean) {
  const get = isPublished ? DocumentAPI.getPublished : DocumentAPI.getById;
  const {
    data: { data: document },
  } = await get(documentId);

  // Seed the store with the data retreived by the API.
  return mapAPIDocumentToModel(document);
}

/**
 * This is the primary entry-point for retreiving a document.
 */
export function useDocument(
  documentId?: string,
  isPublished = false,
): Document | null | undefined {
  const storedDocument = useDocumentStore((state) => (
    documentId ? state.documentsById[documentId] : false
  ));
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const setStoreDocument = useDocumentStore((state) => state.setDocument);

  useEffect(() => {
    if (documentId && storedDocument === undefined) {
      // The document is not in the context and hasn't been loaded yet.
      // Try fetching it from the server.
      setIsLoading(true);
      getDocumentFromAPI(documentId!, isPublished)
        .then((document) => {
          // When a document is cloned, either duplicated by a user or created from
          // an assignment, then the content of the document is copied from the
          // original. Because `version.content` is a blob, the database doesn't do
          // any work to duplicate the relationships. Instead, it just copies the
          // content wholesale. This means that the `documentId` of the pages in the
          // content blob won't match the `documentId` of the document itself.
          // TODO: There is almost certainly a better place to put this logic.
          // It doesn't feel right to do it upon receipt of the document. But I think
          // putting it here will cover all of the cases in which the issue arises.
          if (document.templateSystem === DocumentTemplateSystem.BLOCKS) {
            document.version.pages.forEach((page) => {
              if (isBlockPage(page) && page.documentId !== documentId) {
                page.documentId = document.id;
                page.id = UUID.v1();

                page.grid.blocks.forEach((block) => {
                  block.id = UUID.v1();
                  block.documentId = document.id;
                });
              }
            });
          }

          setStoreDocument(document);
        })
        .catch(setError)
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [
    documentId,
    isPublished,
  ]);

  if (!documentId) {
    // Return undefined to indicate that no document is available at this time,
    // but may become available later if a document ID is provided. This
    // case may occur when a document is a child to some other object that
    // has not yet determined its document ID.
    return undefined;
  }

  if (error) {
    return null;
  }

  if (storedDocument === false || isLoading) {
    return undefined;
  }

  return storedDocument;
}

export function useDuplicateDocument() {
  const setStoreDocument = useDocumentStore((state) => state.setDocument);

  return useCallback(async (templateDocumentId: string) => {
    const response = await DocumentAPI.duplicateDocument(templateDocumentId);
    const { data: { data: documentData } } = response;
    const document = mapAPIDocumentToModel(documentData);

    setStoreDocument(document);
    return document;
  }, []);
}

export function useCreateDocument() {
  const setDocument = useDocumentStore((state) => state.setDocument);
  const applyOperation = useApplyDocumentOperation();
  const requestAnonymouseUser = useUserStore((state) => state.requestAnonymousUser);

  return useCallback(
    async (name: string, format: DocumentFormat, meta: Document['meta']) => {
      await requestAnonymouseUser();
      const response = await DocumentAPI.create({
        name,
        format,
        template_system: DocumentTemplateSystem.BLOCKS,
        meta: JSON.stringify(meta),
      });

      const {
        data: { data: newDocument },
      } = response;
      const document = mapAPIDocumentToModel(newDocument) as Document;
      setDocument(document);
      applyOperation(
        new InitializeVersionOperation(document.id, document.format),
      );
      return document;
    },
    [requestAnonymouseUser],
  );
}

export function useCreateDocumentFromTemplate() {
  const setDocument = useDocumentStore((state) => state.setDocument);
  const applyOperation = useApplyDocumentOperation();
  const requestAnonymouseUser = useUserStore((state) => state.requestAnonymousUser);

  return useCallback(async (
    name: string,
    templateDocumentId: string,
  ) => {
    // Create a document from a pre-existing template.
    const template = DOCUMENT_TEMPLATES.find((t) => t.documentId === templateDocumentId);
    if (!template) {
      // This is an error. The template does not exist.
      throw new Error('Template not found.');
    }

    // Create a new document and then set its contents to the template.
    await requestAnonymouseUser();
    const response = await DocumentAPI.create({
      name,
      template_system: DocumentTemplateSystem.BLOCKS,
      format: template.format,
      meta: JSON.stringify(template.document.meta),
    });
    const { data: { data: newDocument } } = response;
    const document = mapAPIDocumentToModel(newDocument) as Document;
    const baseVersion = template.document.version as DocumentVersion;

    // Make a copy of the document version. Create new IDs for pages and blocks.
    const newVersion = {
      ...template.document.version,
      templateSystem: DocumentTemplateSystem.BLOCKS,
      id: document.id,
      documentId: document.id,
      publishedAt: null,
      status: 'DRAFT' as const,
      createdAt: new Date(),
      notes: [],
      pages: baseVersion.pages.map((page) => ({
        ...page,
        id: UUID.v1(),
        documentId: document.id,
        grid: isBlockPage(page) ? {
          ...page.grid,
          blocks: page.grid.blocks.map((block) => ({
            ...block,
            id: UUID.v1(),
            documentId: document.id,
          })),
        } : undefined,
      })) as BlockPage[],
    };

    // TODO: Deprecate version.pages in favor of version.content.pages.
    newVersion.content = {
      ...newVersion.content,
      messages: [],
      pages: newVersion.pages,
    };
    document.version = newVersion;
    setDocument(document);

    // This will force a save back to the server with the new version.
    applyOperation(new SetDocumentNameOperation(document.id, name));
    return document;
  }, []);
}

export function useDemoDocument() {
  const demoId = 'try-it';
  const setDocument = useDocumentStore((state) => state.setDocument);
  const applyOperations = useApplyDocumentOperation();
  const [, setSelectedBlockId] = useSelectedBlock();

  useEffect(() => {
    // Put a demo document in the store. This will not be synced to the
    // server.
    const pages = Array(8).fill(0).map((_, i) => ({
      id: UUID.v1(),
      documentId: demoId,
      grid: {
        blocks: [],
        rows: 2,
        style: 'fixed',
        columns: 1,
      },
      meta: '{}',
      position: i,
    })) as BlockPage[];

    const demoDocument: Document = {
      id: 'try-it',
      name: 'My Zine',
      format: DocumentFormat.BOOKLET,

      // This is counterintuitive, but demo documents are something else.
      isDemo: false,
      isPublic: false,
      meta: {
        writingPlan: null,
        importantWords: [],
      },
      templateSystem: DocumentTemplateSystem.BLOCKS,
      submission: null,
      user: null,
      version: {
        id: 'try-it',
        documentId: 'try-it',
        createdAt: new Date(),
        notes: [],
        publishedAt: null,
        status: 'DRAFT',
        pages,
        content: {
          schemaVersion: 20230101,
          pages,
          messages: [],
        },
      },
    };

    setDocument(demoDocument);

    const blockId = UUID.v1();
    applyOperations(new AddBlockOperation(
      demoId,
      demoDocument.version.pages[0].id,
      blockId,
      getBlockType(BlockTypesEnum.Title),
      {
        x: 1, y: 1, width: 1, height: 1,
      },
    ));
    setSelectedBlockId(blockId);
  }, []);

  return useDocument('try-it');
}
